<template>
  <div>
    <div class="project-list">
      <loading :isShowLoading="isShowLoading"></loading>
      <!--project-head-->
      <div
        class="
          project-list-head
          d-flex
          justify-content-between
          align-items-center
          mb-5
        "
      >
        <div class="project-head-title">
          <h1
            v-if="head_title != ''"
            class="edc-color-primary fs-2 fw-bold edc-section-title"
          >
            {{ $t("title." + head_title) }}
          </h1>
        </div>
        <div class="project-head-choose-display d-none d-sm-block">
          <div class="d-flex justify-content-between align-items-center">
            <span class="fs-6 ecd-text-muted">{{ $t("title.display") }}</span>
            <button
              type="button"
              @click.prevent="displayBox()"
              class="btn-display grid mx-1 p-1 rounded-10"
              aria-label="Display Box"
              :class="{
                active: gridType === 'box',
              }"
            >
              <i class="fas fa-th-large fs-2"></i>
            </button>
            <button
              type="button"
              @click.prevent="displayRow()"
              class="btn-display list mx-1 p-1 rounded-10"
              :class="{
                active: gridType === 'row',
              }"
              aria-label="Display Row"
            >
              <i class="fas fa-bars fs-2"></i>
            </button>
          </div>
        </div>
      </div>
      <!--//project-head-->
      <div class="row overflow-hidden" v-if="!isEmptyData">
        <!--project-item-->
        <project
          v-for="(item, index) in projects"
          :key="index"
          :project_data="item"
        ></project>
        <!--//project-item-->
      </div>
      <div v-else>
        <div class="alert alert-danger">
          {{ $t("message.not_project_found") }}
        </div>
      </div>
    </div>
    <!--my-donations-loadmore-->
    <loading-page :isShow="isShowLoading"></loading-page>
    <div
      v-if="isShowLoadMoreButton"
      class="my-donations-loadmore my-3 py-3 text-center fs-5 fw-bold"
    >
      <a href="#" @click.prevent="loadMore()" class="edc-color-primary"
        ><span class="ms-4 d-inline">{{ $t("btn.load_more") }}</span>
        <i class="fas fa-chevron-down"></i
      ></a>
    </div>
    <!--//my-donations--->

  </div>
</template>

<script>

import Project from "@/components/Project.vue";
import Loading from "@/components/Loading.vue";
import { mapGetters, mapActions } from "vuex";
import LoadingPage from "@/components/LoadingPage.vue";
export default {
  name: "projects-list",
  props: {
    isResetOnLoad: { type: Boolean, default: false },
    limit: { type: Number, default: 3 },
    head_title: { type: String, default: "" },
  },
  components: {
    Project,
    Loading,
    LoadingPage,
  },
  computed: {
    ...mapGetters("project", [
      "projects",
      "isShowLoadMoreButton",
      "isEmptyData",
      "total",
      "isShowLoading",
      "offset"
    ]),
  },
  data() {
    return {
      items: [],
      gridType: "box",
    };
  },
  methods: {
    ...mapActions("project", ["getProjects", "resetProjects"]),
    loadMore() {
      this.get_api_projects();
    },
    get_api_projects() {
      this.getProjects({ limit: this.limit});
    },
    displayRow() {
      this.$root.$emit(
        "updateProjectView",
        "col-lg-12 col-md-12",
        "display-list"
      );
      localStorage.setItem("project-display", "row");
      this.gridType = "row";
    },
    displayBox() {
      this.$root.$emit("updateProjectView", "col-lg-4 col-md-6", "");
      localStorage.setItem("project-display", "box");
      this.gridType = "box";
    },
  },
  created() {
    if (this.isResetOnLoad === true) {
      this.resetProjects();
    }

    if(this.projects.length <= 0){
      this.getProjects({ limit: this.limit});
    }
    
    this.gridType = localStorage.getItem("project-display");
  },
    mounted() {
        let _this = this;
        this.$root.$on("authLogin",(payload)=>{
            this.getProjects();
        });
        this.$root.$on("authLogout",()=>{
            this.getProjects();
        });
    },
};
</script>